<template>
  <b-modal
    visible
    scrollable
    centered
    size="lg"
    :title="'Создание нового города'"
    :hide-footer="isLoading"
    @hidden="onClose"
    @ok="onClose"
  >
    <template v-if="isLoading">
      <preloader style="margin-top: 20px" />
    </template>

    <template v-else>
      <b-row>
        <b-col>
          <b-form-group
            label="Полное название города"
          >
            <b-form-input
              v-model="cityData.fullName"
              type="text"
              :placeholder="'Введите полное название города'"
              trim
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-form-group
            label="Название города"
            label-class="required"
          >
            <b-form-input
              v-model="cityData.name"
              type="text"
              :placeholder="'Введите название города'"
              trim
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-form-group
            label="Широта"
          >
            <b-form-input
              v-model="cityData.latitude"
              type="text"
              :placeholder="'Введите широту'"
              trim
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-form-group
            label="Долгота"
          >
            <b-form-input
              v-model="cityData.longitude"
              type="text"
              :placeholder="'Введите долготу'"
              trim
            />
          </b-form-group>
        </b-col>
      </b-row>
    </template>

    <template #modal-footer>
      <b-button
        variant="primary"
        :type="$const.PRIMARY_BUTTON"
        class="float-right"
        :disabled="isLoading"
        @click="onCreateCity"
      >
        Сохранить город
      </b-button>
      <b-button
        variant="danger"
        :type="$const.PRIMARY_BUTTON"
        class="float-right ml-2"
        :disabled="isLoading"
        @click="onClose"
      >
        Отмена
      </b-button>
    </template>
  </b-modal>
</template>

<script>
// import {
//   // formatISO,
//   format,
// } from '@evd3v/date-fns';
import Preloader from '@/components/Preloader';
import { clinicService } from '@/services';
import {
  showValidationErrorCustomMessage,
  showSuccessCustomMessage,
  showErrorCustomMessage,
} from '@/helpers/messages';

export default {
  name: 'CityCreateModal',
  components: {
    Preloader,
  },
  data() {
    return {
      isOpen: true,
      isLoading: false,
      cityData: {
        name: '',
        fullName: '',
        longitude: null,
        latitude: null,
      },
    };
  },
  methods: {
    async onCreateCity() {
      if (!this.cityData.name) {
        showValidationErrorCustomMessage('Заполните обязательные поля');
        return;
      }
      await this.createCity(this.cityData);
      await this.$emit('afterSave');
      this.onClose();
    },
    onClose() {
      this.$emit('input', false);
    },
    async createCity(obj) {
      try {
        await clinicService.createCity(obj);
        showSuccessCustomMessage('Город успешно сохранен!');
      } catch (err) {
        showErrorCustomMessage('Не удалось сохранить город');
        console.warn(err);
        throw err;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.passwordBtn:focus {
  box-shadow: none;
}
</style>
